import React from 'react';

import {ImageryCrop} from '@/enums';

export interface PictureSource
  extends React.SourceHTMLAttributes<HTMLSourceElement> {
  crop?: ImageryCrop;
}

export interface ImageProps
  extends Pick<React.HTMLProps<HTMLImageElement>, 'aria-hidden'> {
  alt?: string;
  className?: string;
  componentName?: string;
  crop?: ImageryCrop;
  decoding?: 'async' | 'auto' | 'sync';
  height?: number;
  loading?: 'eager' | 'lazy';
  src: string;
  srcSet?: string;
  sources?: PictureSource[];
  width?: number;
}

/**
 * Temporarily exporting for use in one-off image implementation in Testimonial
 */
export function getImagerySrc(src = '', options = {}) {
  const opts = Object.entries(options).filter(
    ([, value]) => typeof value !== 'undefined',
  );

  const optionsString = opts.reduce((acc, [key, value], index) => {
    if (key === 'crop' && value === ImageryCrop.None) {
      return acc;
    }
    return `${acc}${
      index === 0 && !src.includes('?') ? '?' : '&'
    }${key}=${value}`;
  }, '');

  return `${src}${optionsString}`;
}

export default function Image({
  alt = '',
  className,
  componentName = 'image',
  crop = ImageryCrop.None,
  decoding,
  height,
  loading,
  src,
  srcSet,
  sources,
  width,
  'aria-hidden': ariaHidden,
}: ImageProps) {
  return (
    <picture data-component-name={componentName}>
      {sources?.map((source, index) => {
        return (
          <source
            key={index}
            srcSet={getImagerySrc(source.srcSet, {
              width: source.width,
              height: source.height,
              crop: source.crop,
            })}
            media={source.media}
            width={source.width}
            height={source.height}
            type={source.type}
          />
        );
      })}
      <img
        alt={alt}
        className={className}
        decoding={decoding}
        height={height}
        loading={loading}
        src={getImagerySrc(src, {width, height, crop})}
        srcSet={srcSet}
        width={width}
        aria-hidden={ariaHidden}
      />
    </picture>
  );
}
